import React from 'react'

function Group() {
  return (
    <div className='g'>
      <h2 className='fs-2'>DIVINE TUBES A PART OF CHROMEA ROD AND TUBE COMPANY</h2>
      <h4>OUR GROUP COMPANY </h4>
      <h4>1 - HONEYWELL HYDRAULIC</h4>
      <h4>2 - SHREE HARI SUPER FINISH</h4>
    </div>
  )
}

export default Group
